import React from 'react';
import './MassageService.scss';
import massageImage from '../../assets/servicePage/masaj.png';

const MassageService = () => {
    return (
        <section id="masaj" className='massage-section section-common section-bg'>
            <div className="container">
                {/* Secțiunea Despre Masaj */}
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="massage-text" data-aos="fade-up" data-aos-duration="2000">
                            <h2>Servicii de Masaj</h2>
                            <p>
                                Terapeutul folosește tehnici specializate pentru a combate durerile musculare, a elibera tensiunea și a stimula circulația sanguină. Expertiza sa ajută la recuperarea după exerciții fizice intense, îmbunătățirea flexibilității articulațiilor și reducerea stresului. Prin abordarea precisă a zonelor afectate, cum ar fi spatele, gâtul sau membrele, terapeutul contribuie la ameliorarea disconfortului, oferind un tratament eficient pentru persoanele cu dureri cronice sau acute.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="massage-img" data-aos="fade-up" data-aos-duration="2000">
                            <img src={massageImage} alt="Masaj" />
                        </div>
                    </div>
                </div>

                {/* Masaj de relaxare */}
                <div className="service-category" data-aos="fade-up" data-aos-duration="2000">
                    <h4>Masaj de relaxare</h4>
                    <p>Masajul de relaxare este o tehnică terapeutică ce utilizează manevre delicate și ritmice pentru a reduce tensiunea, stresul și oboseala acumulată. Scopul principal este de a induce o stare de calm și bine, atât pe plan fizic, cât și mental.</p>
                    <ul>
                        <li>
                            Echilibru emoțional
                            <p>Reduce simptomele de stres și depresie ușoară, îmbunătățind starea generală de spirit.</p>
                        </li>
                        <li>
                            Îmbunătățirea somnului
                            <p>Facilitează un somn odihnitor și reparativ prin relaxare mentală și fizică.</p>
                        </li>
                        <li>
                            Recuperare fizică
                            <p>Ameliorează durerile musculare și disconfortul asociat cu statul îndelungat.</p>
                        </li>
                        <li>
                            Îmbunătățirea concentrării
                            <p>Duce la o mai mare claritate mentală prin reducerea stresului.</p>
                        </li>
                    </ul>
                </div>

                {/* Masaj terapeutic */}
                <div className="service-category" data-aos="fade-up" data-aos-duration="2000">
                    <h4>Masaj terapeutic</h4>
                    <p>Masajul terapeutic este o formă de terapie manuală care implică manipularea țesuturilor moi ale corpului pentru îmbunătățirea sănătății fizice și mentale, fiind personalizat în funcție de nevoile fiecărui pacient.</p>
                    <ul>
                        <li>
                            Ameliorarea durerii
                            <p>Tratează eficient durerile musculare și articulare.</p>
                        </li>
                        <li>
                            Îmbunătățirea circulației
                            <p>Stimulează circulația sanguină și limfatică pentru o mai bună oxigenare a țesuturilor.</p>
                        </li>
                        <li>
                            Recuperare funcțională
                            <p>Ajută la recuperarea după accidente sau intervenții chirurgicale.</p>
                        </li>
                        <li>
                            Reducerea stresului
                            <p>Diminuează anxietatea și tensiunea acumulată.</p>
                        </li>
                    </ul>
                </div>

                {/* Masaj cu bete de bambus */}
                <div className="service-category" data-aos="fade-up" data-aos-duration="2000">
                    <h4>Masaj cu bețe de bambus</h4>
                    <p>O tehnică terapeutică și relaxantă ce utilizează bețe de bambus de diferite dimensiuni, oferind beneficii atât fizice, cât și mentale. Această metodă provine din tradițiile asiatice și este apreciată pentru eficiența sa.</p>
                    <ul>
                        <li>
                            Relaxare profundă
                            <p>Reduce stresul și tensiunea, inducând o stare de calm și liniște mentală.</p>
                        </li>
                        <li>
                            Îmbunătățirea circulației
                            <p>Stimulează fluxul sanguin și limfatic, ajutând la detoxifierea organismului.</p>
                        </li>
                        <li>
                            Reducerea durerilor
                            <p>Ajută la eliberarea contracturilor și nodurilor musculare prin presiune profundă.</p>
                        </li>
                        <li>
                            Modelarea corporală și energizare
                            <p>Tonifică pielea și stimulează punctele energetice ale corpului.</p>
                        </li>
                    </ul>
                </div>

                {/* Masaj cu pietre calde */}
                <div className="service-category" data-aos="fade-up" data-aos-duration="2000">
                    <h4>Masaj cu pietre calde</h4>
                    <p>O tehnică de relaxare și terapie holistică ce utilizează pietre vulcanice netede, încălzite la o temperatură confortabilă, combinând masajul tradițional cu căldura terapeutică.</p>
                    <ul>
                        <li>
                            Relaxare profundă
                            <p>Căldura pietrelor relaxează rapid mușchii și induce calm mental.</p>
                        </li>
                        <li>
                            Îmbunătățirea circulației
                            <p>Stimulează fluxul sanguin și ajută la eliminarea toxinelor.</p>
                        </li>
                        <li>
                            Eliminarea tensiunilor
                            <p>Eficient pentru dureri cronice și disconfort postural.</p>
                        </li>
                        <li>
                            Ameliorarea stresului
                            <p>Îmbunătățește calitatea somnului și reduce anxietatea.</p>
                        </li>
                    </ul>
                </div>

                {/* Masaj contrast pietre calde si reci */}
                <div className="service-category" data-aos="fade-up" data-aos-duration="2000">
                    <h4>Masaj contrast pietre calde și reci</h4>
                    <p>O tehnică inovatoare care combină beneficiile termoterapiei cu crioterapia, oferind efecte puternice asupra circulației și revitalizării musculare.</p>
                    <ul>
                        <li>
                            Stimularea circulației
                            <p>Alternarea temperaturilor îmbunătățește fluxul sanguin și limfatic.</p>
                        </li>
                        <li>
                            Relaxare musculară
                            <p>Pietrele calde relaxează, iar cele reci reduc inflamațiile.</p>
                        </li>
                        <li>
                            Revitalizare și tonifiere
                            <p>Stimulează regenerarea celulară și tonifierea pielii.</p>
                        </li>
                        <li>
                            Echilibrare energetică
                            <p>Restabilește echilibrul energetic și elimină blocajele.</p>
                        </li>
                    </ul>
                </div>

                {/* Drenaj limfatic */}
                <div className="service-category" data-aos="fade-up" data-aos-duration="2000">
                    <h4>Drenaj limfatic</h4>
                    <p>O tehnică specializată de masaj care stimulează sistemul limfatic pentru eliminarea toxinelor și a excesului de lichide din organism, îmbunătățind sănătatea și aspectul pielii.</p>
                    <ul>
                        <li>
                            Detoxifiere
                            <p>Elimină toxinele și sprijină funcționarea organelor.</p>
                        </li>
                        <li>
                            Stimulare imunitară
                            <p>Îmbunătățește funcționarea sistemului limfatic și imunitar.</p>
                        </li>
                        <li>
                            Îmbunătățirea aspectului
                            <p>Reduce celulita și aspectul pufos al pielii.</p>
                        </li>
                        <li>
                            Regenerare tisulară
                            <p>Ajută la vindecarea cicatricilor și leziunilor.</p>
                        </li>
                    </ul>
                </div>

                {/* Reflexoterapie */}
                <div className="service-category" data-aos="fade-up" data-aos-duration="2000">
                    <h4>Reflexoterapie</h4>
                    <p>O tehnică terapeutică naturală care implică aplicarea presiunii asupra punctelor reflexogene de pe tălpi, palme și urechi, pentru restabilirea echilibrului energetic și stimularea vindecării naturale.</p>
                    <ul>
                        <li>
                            Relaxare și echilibrare
                            <p>Reduce anxietatea și promovează calmul prin eliberarea endorfinelor.</p>
                        </li>
                        <li>
                            Îmbunătățirea circulației
                            <p>Stimulează transportul oxigenului și nutrienților în corp.</p>
                        </li>
                        <li>
                            Ameliorarea durerilor
                            <p>Eficientă pentru dureri de cap, migrene și dureri articulare.</p>
                        </li>
                        <li>
                            Detoxifiere și regenerare
                            <p>Stimulează procesele naturale de vindecare ale organismului.</p>
                        </li>
                    </ul>
                </div>

                {/* Lista de Prețuri */}
                <div className="service-category" data-aos="fade-up" data-aos-duration="2000">
                    <h4>Tarife Servicii Masaj</h4>
                    <p>Oferim o gamă variată de servicii de masaj, fiecare adaptat nevoilor specifice ale pacienților noștri. Toate serviciile sunt efectuate de terapeuți calificați, cu experiență în domeniu.</p>
                    <ul>
                        <li>
                            Masaj relaxare și terapeutic
                            <p>150 RON - Tehnici specializate pentru relaxare musculară și reducerea stresului.</p>
                        </li>
                        <li>
                            Drenaj limfatic
                            <p>200 RON - Tehnică specifică pentru eliminarea toxinelor și reducerea retenției de lichide.</p>
                        </li>
                        <li>
                            Masaj cu bețe de bambus
                            <p>230 RON - Tehnică tradițională pentru relaxare profundă și tonifiere musculară.</p>
                        </li>
                        <li>
                            Masaj cu pietre (calde/contrast)
                            <p>230 RON - Terapie cu pietre calde sau contrast pentru relaxare și echilibrare energetică.</p>
                        </li>
                        <li>
                            Masaj cu ventuze
                            <p>230 RON - Tehnică tradițională pentru detoxifiere și îmbunătățirea circulației.</p>
                        </li>
                        <li>
                            Masaj Siberian
                            <p>230 RON - Tehnică specială pentru revitalizare și energizare.</p>
                        </li>
                        <li>
                            Masaj cu pistolul de masaj
                            <p>250 RON - Tehnologie modernă pentru relaxare musculară profundă și recuperare.</p>
                        </li>
                        <li>
                            Reflexoterapie
                            <p>300 RON - Terapie specializată prin stimularea punctelor reflexogene.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default MassageService;
